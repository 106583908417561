// alioss.js
import OSS from 'ali-oss'
import ajax from '../config/requestUtil.js'
// const OSS = require('ali-oss');

function getKey() {
  return new Promise((resolve, reject) => {
    ajax.post(`/c/app/oss/stsCredentials`, {}).then(res => {
      resolve(res.data)
    }).reject(() => {
      reject(false)
    })
  })
}

export async function client() {
  const r = await getKey()
  return new OSS({
    region: r.region,
    accessKeyId: r.accessKeyId,
    accessKeySecret: r.accessKeySecret,
    bucket: r.bucketName,
    stsToken: r.securityToken
  })
}
