<template>
    <div class="uploadoss-component">
        <van-uploader v-model="fileList" :after-read="afterRead" :max-count="limits" :max-size="10 * 1024 * 1024"
            @oversize="onOversize" @delete="deleteImg" :multiple="limits > 1" :readonly="readonly" />
    </div>
</template>
<script>
import { client } from '@/lib/alioss'
import { upload, uploadSuccess } from "@/lib/appMethod";

export default {
    props: {
        limits: {
            type: Number,
            default: 1,
        },
        ossPath: {
            type: String,
            default: 'ACT/uploadOSS'
        },
        value: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            fileList: [],
            readonly: this.appTypeStr !== 'mini'
        }
    },
    watch: {
        value: {
            handler(v) {
                this.fileList = v;
                this.$nextTick(this.bind)
            },
            deep: true,
            immediate: true
        }
    },
    async mounted() {
        if (process.env.NODE_ENV == "development") {
            this.readonly = this.isAPP() ? true : false;
        }
        this.bind();
    },
    beforeDestroy() {
        this.unbind();
    },
    methods: {
        isAPP() {
            if (this.appTypeStr == "and") {
                return window.App;
            }
            if (this.appTypeStr == "ios") {
                return window.webkit;
            }
        },
        unbind() {
            if (this.dom) {
                this.dom.removeEventListener('click', this.upload);
            }
        },
        bind() {console.log(this.readonly);
            if (this.readonly) {
                this.unbind();
                this.dom = this.$el.querySelector('.van-uploader__upload');
                if (this.dom) {
                    this.dom.addEventListener('click', this.upload);
                }
            }
        },
        upload() {console.log(6666666666);
            upload(this.limits)
            // uploadSuccess只会执行一次 所以上传的时候每次都要调用
            uploadSuccess().then((arr) => {
                console.log(arr, 'bb');
                if(arr.length==1){
                    const sp= arr[0].split(',');
                    if(sp.length>1){
                        arr=sp;
                    }
                }
                if (this.fileList.length + arr.length > this.limits) {
                    this.$toast('超出限制');
                    return
                }
                this.modelData([...this.fileList, ...arr.map((item) => { return { url: item } })]);
            }).catch((r) => {
                console.log('errowwww');
            })
        },
        deleteImg(data) {
            console.log(data, this.value);
            this.modelData(this.value.filter(d => {
                return (d.url || d.content) != (data.url || d.content)
            }));
        },
        modelData(data) {
            this.$emit('input', data)
        },
        afterRead(file) {
            const FF = Array.isArray(file) ? file : [file]
            // 生成文件名
            FF.map(async (item) => {
                item.status = 'uploading'
                const fileName = `${this.ossPath}/z${Date.now()}-${item.file.name}`;
                try {
                    // 上传文件到阿里云OSS
                    const cli = await client()
                    const result = await cli.put(fileName, item.file);
                    console.log('File uploaded:', result);
                    item.url = result.url;
                    item.status = ''
                    console.log(666, file, this.fileList);
                } catch (error) {
                    item.status = 'failed';
                    console.error('Upload failed:', error);
                }

                this.modelData(this.fileList)
            })

        },
        onOversize(file) {
            console.log(file);
            Toast('图片大小不能超过 10M')
        },
    }
}
</script>

<style lang='less' scoped>
.uploadoss-component {
    position: relative;
    width: fit-content;

    /deep/.van-uploader__wrapper {
        .van-uploader__upload {
            background: #f5f5f5;
            margin-right: 0;
        }

        .van-uploader__preview {
            margin-right: 18px;
        }

        .van-uploader__upload,
        .van-uploader__preview-image {
            width: 144px;
            height: 144px;
            border-radius: 8px;
            overflow: hidden;
        }

        .van-uploader__preview-delete {
            width: 32px;
            height: 32px;
            top: -8px;
            right: -8px;
            border-radius: 50%;
            text-align: center;
            line-height: 24px;

            .van-uploader__preview-delete-icon {
                font-size: 20px;
                position: initial;
                transform: none;
            }
        }
    }
}
</style>
